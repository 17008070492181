<template>
  <div class=".gg-container-1">
    <div class="search-container">
      <div
        style="margin-left: auto; display: flex; justify-content: space-around"
      >
        <div class="search-container-fn-input" v-if="operateList.indexOf('add') > -1">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-refresh"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
                                    background-color: #2362FB; border-color: #2362FB"
            @click="handleAdd('add', 'add_first')"
            >新增一级分类
          </el-button>
        </div>
      </div>
    </div>
    <el-table
      :data="tableData"
      size="mini"
      v-loading="listLoading"
      style="width: 100%;margin-bottom: 20px;"
      height="725"
      row-key="id"
      border
      default-expand-all
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
    >
      <el-table-column prop="cate_name" label="分类" width="auto">
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="200">
        <template slot-scope="scope">
          <el-button
            @click.native.prevent="handleAdd('add', 'add_second', scope.row)"
            type="text"
            size="small"
            v-if="scope.row.children"
          >
            <div  v-if="operateList.indexOf('add') > -1">新增二级分类</div>
          </el-button>
          <el-button
            @click.native.prevent="handleEdit('edit', '', scope.row)"
            type="text"
            size="small"
          >
            <div v-if="operateList.indexOf('update') > -1">编辑</div>
          </el-button>
          <el-button
            @click.native.prevent="deleteRow(scope.row)"
            type="text"
            size="small"
          >
            <div v-if="operateList.indexOf('delete') > -1">删除</div>
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog :visible.sync="dialogVisible" width="20%" :show-close="false">
      <div>
        <div style="margin-bottom:20px">
          <span style="font-size:22px;border-bottom: #2362FB 3px solid;"
            >{{ btn_type == "edit" ? "编辑" : "新增" }}分类</span
          >
        </div>
        <div style="display: flex;align-items: center;">
          <span style="margin-right:20px"
            >分类名称({{ type == "add_second" ? "二级" : "一级" }})</span
          >
          <el-input
            v-model="info.cate_name"
            placeholder="请输入分类名称"
            style="width:200px"
            size="mini"
          ></el-input>
        </div>
        <div
          style="display: flex;align-items: center;margin-top:20px"
          v-if="type == 'add_second'"
        >
          <span style="margin-right:20px">分类图片</span>
          <UploadImage
            ref="uploadImg"
            :limit="1"
            :url="info.image?[info.image]:[]"
						v-on:setImageUrl="setImageUrl"
          ></UploadImage>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="
            dialogVisible = false;
            info = { cate_name: '' };
          "
          size="mini"
          >取 消</el-button
        >
        <el-button type="primary" @click="_ok" size="mini">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getCateList,
  addCategory,
  updateCategory,
  deleteCategory,
} from "@/newApi/goods";
import { Message } from "element-ui";
import UploadImage from "@/components/UploadImage/UploadImage";

export default {
  name: "memberList",
  async created() {
    this.getList();
  },
  mounted() {
        this.$store.dispatch("user/getOperates", this.$route.path).then((res) => {
      console.log(res)
      this.operateList = res;
    });
  },
  data() {
    return {
      operateList: [],
      listLoading: true,
      tableData: [],
      fullscreenLoading: false,
      dialogVisible: false,
      info: {
        cate_name: "",
      },
      dataInfo: {},
      type: "", //add_first一级分类 add_second二级分类
      btn_type: "", //add新增 edit编辑
    };
  },
  components: {
    UploadImage,
  },

  methods: {
    //删除
    deleteRow(data) {
      //提醒
      this.$confirm("此操作将永久删除该分类, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let info = {
            id: [data.id],
            type: data.children ? 1 : 2,
          };
          deleteCategory(info).then((response) => {
            let data = response;
            if (data.code == 200) {
              Message({
                message: "删除成功",
                type: "success",
              });
              this.getList();
            } else {
              Message({
                message: data.msg,
                type: "error",
              });
            }
          });
        })
        .catch(() => {});
    },
    _updateCategory(data) {
      updateCategory(data).then((response) => {
        let data = response;
        if (data.code == 200) {
          Message({
            message: "编辑成功",
            type: "success",
          });
          this.dialogVisible = false;
          this.getList();
          this.info = {
            cate_name: "",
          };
        } else {
          Message({
            message: data.msg,
            type: "error",
          });
        }
      });
    },

    //编辑分类
    handleEdit(btn_type, type, row) {
      this.btn_type = btn_type;
      this.info.cate_name = row.cate_name;
      this.info.image = row.image;
      this.info.id = row.id;
      this.dialogVisible = true;
      console.log(row, 22222);
      if (row.children) {
        console.log("我是一级分类");
        this.type = "add_first";
      } else {
        console.log("我是二级分类");
        this.type = "add_second";
      }
    },
    _postAddCategory(data) {
      addCategory(data).then((response) => {
        let data = response;
        if (data.code == 200) {
          Message({
            message: "新增成功",
            type: "success",
          });
          this.dialogVisible = false;
          this.getList();
          this.info = {
            cate_name: "",
          };
        } else {
          Message({
            message: data.msg,
            type: "error",
          });
        }
      });
    },
    async _ok() {
      if (!this.info.cate_name)
        return Message({
          message: "请输入分类名称",
          type: "error",
        });
      if (this.type == "add_first") {
        console.log("我是一级分类");
        this.info.pid = 0;
        if (this.btn_type == "edit") await this._updateCategory(this.info);
        else await this._postAddCategory(this.info);
      } else {
        if (this.btn_type == "edit") {
          if(!this.info.image){
            this.info.image = this.$refs["uploadImg"].imageUrl
          }
          await this._updateCategory(this.info);
        }else {
          this.info.image = this.$refs["uploadImg"].imageUrl
          await this._postAddCategory(this.info);
        }
      }
    },
    //新增一级/二级分类
    handleAdd(btn_type, type, row) {
      console.log(type, row, btn_type);
      this.dialogVisible = true;
      this.type = type;
      this.btn_type = btn_type;
      if (type == "add_second") {
        this.info.pid = row.id;
      }
    },
    getList() {
      this.listLoading = true;
      let data = {
        page: 1,
        limit: 1000,
      };
      getCateList(data)
        .then((response) => {
          let data = response;
          if (data.code == 200) {
            let _data = data.data;
            this.tableData = _data.list;
          }
          this.fullscreenLoading = false;
          this.listLoading = false;
        })
        .catch((error) => {
          Message({
            message: error.message,
            type: "error",
          });
          this.listLoading = false;
          this.fullscreenLoading = false;
        });
    },
    setImageUrl (imageUrl) {
      this.info.image = imageUrl
    },
    reset() {
      // this.searchParams = {};
      this.listQuery.page = 1;
      this.getList();
    },
  },
};
</script>

<style scoped lang="scss">
/deep/.switch .el-switch__label {
  position: absolute;
  display: none;
  color: #fff !important;
}

/deep/.switch {
  margin-left: 8px;
}

/*打开时文字位置设置*/
/deep/.switch .el-switch__label--right {
  z-index: 1;
}

/* 调整打开时文字的显示位子 */
/deep/.switch .el-switch__label--right span {
  margin-left: -2px;
}

/*关闭时文字位置设置*/
/deep/.switch .el-switch__label--left {
  z-index: 1;
}

/* 调整关闭时文字的显示位子 */
/deep/.switch .el-switch__label--left span {
  margin-left: 22px;
}

/*显示文字*/
/deep/.switch .el-switch__label.is-active {
  display: block;
}

/* 调整按钮的宽度 */
/deep/.switch.el-switch .el-switch__core,
.el-switch .el-switch__label {
  width: 60px !important;
  margin: 0;
}
.row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-left: 30px;
}
.font-mar {
  margin-right: 20px;
}
</style>

import request from '@/utils/request'

//商品分类列表页
export function getCateList(params) {
    return request({
        url: '/admin/cate-list',
        method: 'GET',
        params
    })
}
//添加分类
export function addCategory(data) {
    return request({
        url: '/admin/add-category',
        method: 'post',
        data
    })
}
//更新分类
export function updateCategory(data) {
    return request({
        url: '/admin/update-cate/' + data.id,
        method: 'post',
        data
    })
}
//删除分类
export function deleteCategory(data) {
    return request({
        url: '/admin/delete-cate',
        method: 'post',
        data
    })
}
//商品列表
export function getGoodsList(params) {
    return request({
        url: '/admin/goods-list',
        method: 'GET',
        params
    })
}
//批量设置首页推荐
export function setRecommendGoods(data) {
    return request({
        url: '/admin/set-recommend-goods',
        method: 'post',
        data
    })
}
//修改商品状态
export function editGoodsStatus(data) {
    return request({
        url: '/admin/edit-goods-status/' + data.id,
        method: 'post',
        data
    })
}
//删除商品
export function deleteGoods(data) {
    return request({
        url: '/admin/delete-goods',
        method: 'post',
        data
    })
}
//商品详情
export function getGoodsInfo(params) {
    return request({
        url: '/admin/get-goods/' + params.id,
        method: 'GET',
        params
    })
}

//添加商品
export function postAddGoods(data) {
    return request({
        url: '/admin/add-goods',
        method: 'post',
        data
    })
}
//编辑商品
export function putEditGoods(data) {
    return request({
        url: '/admin/edit-goods/' + data.id,
        method: 'put',
        data
    })
}
//商品套餐列表
export function getTc(params) {
    return request({
        url: '/admin/tc',
        method: 'GET',
        params
    })
}
//添加套餐
export function postAddTc(data) {
    return request({
        url: '/admin/tc',
        method: 'post',
        data
    })
}
//修改套餐
export function patchTc(data) {
    return request({
        url: '/admin/tc/' + data.id,
        method: 'patch',
        data
    })
}
//套餐详情
// admin/tc-info/{tc}
export function getTcInfo(params) {
    return request({
        url: '/admin/tc-info/' + params.id,
        method: 'get',
        params
    })
}

//修改套餐状态
export function postTcStatus(data) {
    return request({
        url: '/admin/tc/status/' + data.id,
        method: 'post',
        data
    })
}
//批量删除套餐
export function deleteTcBatchDelete(data) {
    return request({
        url: '/admin/tc-batch-delete',
        method: 'delete',
        data
    })
}

//批量推荐套餐
export function postTcBatchRecommend(data) {
    return request({
        url: '/admin/tc-batch-recommend',
        method: 'post',
        data
    })
}

export function setGoodsRecommend(data) {
    return request({
        url: '/admin/setGoodsRecommend',
        method: 'post',
        data
    })
}
